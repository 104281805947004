
/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage'; 

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const IDMPage = props => {
	return (
		<CaseStudyPage link="idm" title="Design e Progettazione Web - IDM"
		relPath="idm" description="Design e progettazione web per IDM - Music Startup.">
		</CaseStudyPage>
	);
};

export default IDMPage;
